import React from "react"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout pageTitle="Contact">
    <h1>Hi people</h1>

  </Layout>
)

export default IndexPage
